import { Container } from 'components';
import { Link } from 'gatsby';
import { Routes } from 'modules/navigation';
import * as React from 'react';
import { staticStyles } from 'style';

const NotFoundPage = () => (
  <>
    <Container>
      <div css={staticStyles.wrapper}>
        <h2 css={staticStyles.sectionTitle}>Page not found</h2>
        <h1 css={staticStyles.title}>
          The link you clicked may be broken or the page may have been removed.
        </h1>

        <p css={staticStyles.text}>
          Visit the{' '}
          <Link to={Routes.Home} css={staticStyles.link}>
            Homepage
          </Link>{' '}
          or{' '}
          <Link to={Routes.Contact} css={staticStyles.link}>
            Contact us
          </Link>{' '}
          about the problem
        </p>
      </div>
    </Container>
  </>
);

export default NotFoundPage;
